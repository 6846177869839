<template>
  <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="보장설계 발송" :topButton="true"  :action-type="actionSlot">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 :scroll-target="lv_HeaderviewScrollCompID"-->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary"  scroll-shadow :scroll-target="lv_HeaderviewScrollCompID">
             <mo-button @click="fn_HistoryBack" class="btn_popup_back" />
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
             <!-- <mo-button @on-header-left-click="fn_HistoryBack"  class="btn_popup_back" />
              <div slot="nav" class="icon-wrapper" @on-header-left-click="fn_BackBtnClicked()" >
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div> -->

              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '보장설계 발송' : '보장설계 발송'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- Content영역 -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30" style="padding-bottom:10px;">
              <ur-box-container alignV="start" componentid="" direction="column" class="info-box mb50"> 
                <div class="info-title-wrap mb10">
                  <span class="fwm fs18rem">발송방식 선택</span>
                </div>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                  <mo-segment-wrapper solid primary v-model="radioValue1" class="chip-type-wrap chip-btn22">
                    <div class="fexTy5 pal0 full">
                      <mo-segment-button value="1" class="btn-arrow-down w100 border_radius--br border_radius--tr pal0 fs17rem fwm ty--print">{{lv_printTypeNm}} 인쇄</mo-segment-button>
                      <mo-segment-button value="1" class="btn-arrow-down w35px border_radius--bl border_radius--tl pal0 ty--print" @click="fn_printType_select_Open()"><mo-icon icon="msp-arrow-down" class="ml0" /></mo-segment-button>
                    </div>

                    <!-- <div class="fexTy5 pal0 full">
                      <mo-segment-button value="1" class="btn-arrow-down w100 bdTy3 border_radius--br border_radius--tr">{{lv_printTypeNm}} 인쇄</mo-segment-button>
                      <mo-segment-button value="1" class="btn-arrow-down w35px bdTy3 border_radius--bl border_radius--tl" @click="fn_printType_select_Open()"><mo-icon icon="msp-arrow-down" class="ml0" /></mo-segment-button>
                    </div> -->

                   <!-- <mo-segment-button value="1" class="btn-arrow-down" @click="fn_printType_select_Open()">{{lv_printTypeNm}} 인쇄<mo-icon icon="msp-arrow-down" /></mo-segment-button> -->
                    <mo-segment-button value="2">이메일</mo-segment-button>
                    <mo-segment-button value="3">문자</mo-segment-button>
                    <mo-segment-button value="4">알림톡</mo-segment-button>
                    <mo-segment-button value="5">비대면화상상담</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container> 
              </ur-box-container> 

              <ur-box-container alignV="start" componentid="" direction="column" class="info-box mb50"> 
                <div class="info-title-wrap mb10">
                  <span class="fwm fs18rem">자료유형 선택</span>
                </div>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                  <mo-segment-wrapper solid primary v-model="radioValue2" class="chip-type-wrap chip-btn22" style="grid-template-columns: 1fr 1fr 1fr;">
                    <!-- <mo-segment-button value="0" :disabled="radioValue1 === '3' || radioValue1 === '4'">시니어</mo-segment-button> -->
                    <mo-segment-button value="1" :disabled="radioValue1 === '3' || radioValue1 === '4'">요약형</mo-segment-button>
                    <mo-segment-button value="2" :disabled="radioValue1 === '3' || radioValue1 === '4'">상세형</mo-segment-button>
                  </mo-segment-wrapper>
                  <!-- 말풍선 추가 start -->
                  <!-- <div v-if="tooltipShow" class="chat-bubble type-M1 blu2" style="left:0px; position:sticky; margin-bottom:-100px; margin-top:5px;">
                    <div class="exp" style="font-size:15px; height:33px;">
                      시니어 자료가 추가 되었어요!<br>24년 상급종합병원 자료를 볼 수 있어요.　</div>
                    <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="fn_closeTooltip" />
                  </div> -->
                  <!-- 말풍선 추가 end -->
                </ur-box-container>
              </ur-box-container>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area" :items="tableData" :multi-select="radioValue1 !== '3' && radioValue1 !== '4'"  :disabled="radioValue1 === '3' || radioValue1 === '4'"> 
              <ur-box-container alignV="start" componentid="" direction="column" class="con-area mb15" v-model="selectedValue2">
                <div class="info-title-wrap mb0">
                  <span class="fwm fs18rem">서류선택</span>
                </div>
              </ur-box-container>  
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum"  >
                <mo-list-item>
                  <!-- 전체체크 -->
                  <mo-checkbox v-model="isSelectAll" @input="selectAllItemsFunc(isSelectAll, bottonScCd)" size="small" :disabled="radioValue1 === '3' || radioValue1 === '4'"/>   
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                     <!-- <span class="sum"> <strong class="crTy-blue3">{{mockCheckCount}}</strong> 건 선택</span>  -->
                     <span v-if="mockCheckCount > 0" class="sum"><strong class="crTy-blue3">{{ mockCheckCount }}</strong> 건 선택</span>
                     <span v-else class="sum">총 <strong class="crTy-blue3">{{ tableData.length }}</strong> 건</span> 
                    </div> 
                  </div>
                </mo-list-item>
              </ur-box-container>

              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list list-pa2024"  :disabled="radioValue1 === '3' || radioValue1 === '4'"> 
                <mo-list :list-data="tableData"  :class="moListDisabled ? 'disabled' : ''" >

                  <template #list-item="{item}">  
                    <mo-list-item >
                      <mo-checkbox   v-model="selectItems" :value="item" :disabled="radioValue1 === '3' || radioValue1 === '4'" />
                      <div class="list-item__contents">
                        <div class="list-item__contents__info pt5 pb0" >
                          <span class="fs14rem crTy-bk1" >{{item.custNm}}</span>
                        </div>
                        <div class="list-item__contents__title" >
                          <span class="name txtSkip" >{{item.otmlFileNm}}</span>
                        </div>
                      </div>
                      <!-- 말풍선 추가 start -->
                      <div id="listTooltip1" v-if="radioValue2 === '2' && item.otmlFileNm === '회사소개'" class="chat-bubble type-M1 blu2" style="width:230px; height:33px ;position:sticky;">
                        <div class="exp" style="font-size:15px; height:33px;">
                          회사 소개 자료가 추가 되었어요!</div>
                        <!-- <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="" /> -->
                      </div>
                      <!-- 말풍선 추가 end -->
                      <!-- 말풍선 추가 start -->
                      <div id="listTooltip2" v-if="radioValue2 === '2' && item.otmlFileNm === '니즈환기'" class="chat-bubble type-M1 blu2" style="width:230px; height:33px ;position:sticky;">
                        <div class="exp" style="font-size:15px; height:33px;">
                          다양한 니즈환기 자료가 추가 되었어요!</div>
                        <!-- <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="" /> -->
                      </div>
                      <!-- 말풍선 추가 end -->
                    </mo-list-item>
                  </template>
                </mo-list>        
              </ur-box-container>
            </ur-box-container>
            <!-- Content 영역 end --> 

            <ur-box-container   alignV="start" componentid="" direction="column" class="ns-btn-relative" :disabled="mockCheckCount === 0" >
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="!(radioValue1 === '3' || radioValue1 === '4') && mockCheckCount < 1"  @click="fn_preview_Open()">미리보기</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="mockCheckCount < 1"  v-if="radioValue1 === '1'"  @click="fn_print_Open()">인쇄</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="mockCheckCount < 1"  v-else-if="(radioValue1 === '2' )  "  @click="fn_OzPrintEmailPrct(selectItems, selectedValue2, selectedValue1, radioValue1)">발송</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-else-if="(radioValue1 === '3' || radioValue1 === '4')  "  @click="fn_OzPrintEmailPrct(selectItems, selectedValue2, selectedValue1, radioValue1)">발송</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="mockCheckCount < 1" v-else-if="radioValue1 === '5'"  @click="fn_OzPrintEmailPrct(selectItems, selectedValue2, selectedValue1, radioValue1)">저장</mo-button> 
              </div>
            </ur-box-container>

          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container> 

    <!-- 인쇄방식 선택 -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        인쇄방식 선택
        <div class="ns-btn-close" @click="fn_printType_select_Close()" name="닫기"></div>
      </template>
       
      <div class="content-area pal0 pb30">
        <ur-box-container alignV="start" componentid="" direction="column" class="btn-list-type">
          <mo-button @click="fn_btnChk('A')" class="ns-btn-check" :class="{ on: btn_index === 'A' }">양면컬러 인쇄<mo-icon icon="msp-check-bold"/></mo-button><!-- class명 on시 파란색 선택 입니다. -->
          <mo-button @click="fn_btnChk('B')" class="ns-btn-check" :class="{ on: btn_index === 'B' }">단면컬러 인쇄<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_btnChk('C')" class="ns-btn-check" :class="{ on: btn_index === 'C' }">양면흑백 인쇄<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_btnChk('D')" class="ns-btn-check" :class="{ on: btn_index === 'D' }">단면흑백 인쇄<mo-icon icon="msp-check-bold"/></mo-button>
        </ur-box-container>
      </div>
    </mo-bottom-sheet>

    <!-- 바텀시트2 : 인쇄  Alert 팝업입니다. -->
    <mo-bottom-sheet ref="print_nsbottomsheet"  class="ns-bottom-sheet msp-bottomSt-alert">
        <div class="customer-info-txt txt-center">
          <p class="ment"><b>선택한 서류를 인쇄하시겠습니까?</b></p>
          <p class="ment"></p>
          <p class="ment">보장설계 인쇄물을 고객님께 전달하지 못하거나 </p>
          <p class="ment">단순 출력한 경우에는 개인정보보호를 위해 즉시  </p>
          <p class="ment">파쇄하시기 바랍니다.</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_print_Close()" name="취소">취소</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OzPrintEmailPrct(selectItems, selectedValue2, selectedValue1, radioValue1)" name="인쇄">인쇄</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 바텀시트3 : 미리보기  Alert 팝업입니다. -->
    <mo-bottom-sheet ref="previewDspchConfirm_nsbottomsheet"  class="ns-bottom-sheet msp-bottomSt-alert">
        <div class="customer-info-txt txt-center">
          <p class="ment"><b>선택한 서류를 미리보시겠습니까?</b></p>
          <p class="ment"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_preview_Close()" name="취소">취소</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OzPrintEmailPrct(selectItems, selectedValue2, selectedValue1, '6')" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>    

    <mo-snackbar ref="actionSnackbar" stackable :duration="1800000">
      인쇄되었습니다.
      <!-- <template v-slot:action> -->
      <template #action="{snackbar}">
        <mo-button @click="snackbarAction(snackbar)" class="snackbar-btn">　확인　</mo-button>
      </template>
    </mo-snackbar>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import UrBoxContainer from '@/_uidev/containers/box/urBoxContainer.vue'
import CtConstants from '@/config/constants/ctConstants'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import moment from 'moment'
import MSPCT161P from '@/ui/ct/MSPCT161P' // 보장발송고객수신목록 
import MSPSA063P from '@/ui/sa/MSPSA063P' // 비대면화상상담고객수신목록 
import MSPCM192P from '@/ui/cm/MSPCM192P.vue' // 이메일발송  

export default {
/***********************************************************************************
 * Vue 파일 속성 정의 영역	                                                       *
***********************************************************************************/
name: "MSPCT160P",
screenId: "MSPCT160P",
// 컴포넌트 선언
isBackKeyHandle: true ,  // 팝업일때 디바이스 백키 직접 핸들처리여부 오션      

components: {
    MSPCT161P,
    MSPSA063P,
    MSPCM192P 
},
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
      return {
      // tooltipShow: false, // 툴팁 출력

      btn_index: 'A',
      lv_printTypeNm: '양면컬러',
      // firstOpen: 1,
      showPopup: false,
      printDspchConfirm: false,
      emailDspchConfirm: false,
      previewDspchConfirm: false,
      emailOzDspchConfirm: false, // [보험거래문서디지털화PJT] 2020-07-09. 추가
      previewOzDspchConfirm: false, // [보험거래문서디지털화PJT] 2020-07-09. 추가
      noFacCnslConfirm: false, // [비대면상담시스템구축PJT] 2021.06.14 추가
      // isNofacCnslAuth: false, // 화상상담 권한
      noFacCnslParams: {},
      lv_NofacCnslCust: {}, // 비대면화상상담 고객
      horizontal: true,

      radioValue1: '1',
      radioValue2: '1',
      radioValue2Bef: '',
 
      isSelectAll: false,
      selectItems: [],
      selectItemsSENI: [],
      selectItemsSMRY: [],
      selectItemsUNTY: [],
      selectItemsSENIinit: true,
      selectItemsSMRYinit: true,
      selectItemsUNTYinit: true,
      copyData: [],
      gtplCllgOtmlVO: [], //
      whlHsmbVO: [], // 전체일 경우
      genHsmbVO: [], // 일반일 경우

      selectedValue1: { key: 'A', label: '양면컬러' },
      selectedData1: [{
        key: 'A',
        label: '양면컬러'
      },
      {
        key: 'B',
        label: '단면컬러'
      },
      {
        key: 'C',
        label: '양면흑백'
      },
      {
        key: 'D',
        label: '단면흑백'
      }],

      selectedValue2: { key: '00', label: '전체 세대원' },
      tableData: [],
      customerCollectionVO: [],

      // gtplReportTile: [
      //   {otmlFileNm: '표지', famYn: 'Y', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 0, title: '표지'},
      //   // {otmlFileNm: '[New] 3大 인생자산 분석 요약본', famYn: 'Y', myYn: 'Y', indivYn: 'Y', PrpsYn: '', title: '[New] 3大 인생자산 분석 요약본'},
      //   {otmlFileNm: '보장자산요약', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 1, title: '보장자산 요약'},
      //   {otmlFileNm: '보장자산요약(세대)', famYn: 'Y', myYn: 'Y', indivYn: 'N', PrpsYn: '', sortNo: 2, title: '[세대]보장자산 요약'},
      //   {otmlFileNm: '계약현황분석(개인)', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 3, title: '계약 현황 분석'},
      //   {otmlFileNm: '계약현황분석(세대)', famYn: 'Y', myYn: 'Y', indivYn: 'N', PrpsYn: '', sortNo: 4, title: '[세대]계약 현황 분석'},
      //   {otmlFileNm: '상품별 보장내역 분석', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 5, title: '상품별 보장내역 분석'},
      //   {otmlFileNm: '설계 전 기간별 보장내역 분석', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 6, title: '설계 전 기간별 보장내역 분석'},
      //   {otmlFileNm: '가족구성원 보장내역 분석', famYn: 'Y', myYn: 'Y', indivYn: 'N', PrpsYn: '', sortNo: 7, title: '[세대]가족구성원 보장내역 분석'},
      //   {otmlFileNm: '통합보장자산 전/후 비교', famYn: 'Y', myYn: 'Y', indivYn: 'Y', PrpsYn: 'Y', sortNo: 8, title: '[세대]설계 후 보장 전후 분석(상품제안)'},
      //   {otmlFileNm: '개인별 보장 전/후 비교', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: 'Y', sortNo: 9, title: '개인별 보장 전후 비교'},
      //   {otmlFileNm: '기간별 보장 전/후 비교', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: 'Y', sortNo: 10, title: '설계 후 기간별 보장내역 분석'},
      //   {otmlFileNm: '가족 통합 보장 전/후 비교', famYn: 'Y', myYn: 'Y', indivYn: 'N', PrpsYn: 'Y', sortNo: 11, title: '[세대]가족 통합 보장 전후 비교'},
      //   {otmlFileNm: '생손보 실손 가입현황', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 12, title: '생손보 실손 가입현황'},
      //   {otmlFileNm: '기타안내', famYn: 'Y', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 13, title: '기타안내'}
      // ],
      // gtplUntyReportTile: [
      //   {otmlFileNm: 'MY기본형_표지', sortNo: 0, title: '표지'},
      //   {otmlFileNm: '당사/타사 보장설계 요약', sortNo: 1, title: '당사/타사 보장설계 요약'},
      //   {otmlFileNm: '건강자산 인체해부도', sortNo: 2, title: '건강자산 인체해부도'},
      //   {otmlFileNm: '건강자산 보장지수 분석', sortNo: 3, title: '건강자산 보장지수 분석'},
      //   {otmlFileNm: '보장자산요약', sortNo: 4, title: '보장자산 요약'},
      //   {otmlFileNm: '당사/타사 상품별 보장자산', sortNo: 5, title: '당사/타사 상품별 보장자산'},
      //   {otmlFileNm: '설계 전 기간별 보장내역 분석', sortNo: 6, title: '설계 전 기간별 보장내역 분석'},
      //   {otmlFileNm: '통합보장자산 전/후 비교', PrpsYn: 'Y', sortNo: 7, title: '설계 후 보장 전후 분석(상품제안)'},
      //   {otmlFileNm: '개인별 보장 전/후 비교', PrpsYn: 'Y', sortNo: 8, title: '개인별 보장 전후 비교'},
      //   {otmlFileNm: '기간별 보장 전/후 비교', PrpsYn: 'Y', sortNo: 9, title: '설계 후 기간별 보장내역 분석'},
      //   {otmlFileNm: '생손보 실손 가입현황', sortNo: 10, title: '생손보 실손 가입현황'},
      //   {otmlFileNm: '보험가입한도상세인쇄형', sortNo: 11, title: '타사 상품 가입 상세형'},
      //   {otmlFileNm: '기타안내', sortNo: 12, title: '기타안내사항'},
      // ],
      // 2차 개발시 현행화 적용함(2023/05/04)
      gtplReportTile: [
        {otmlFileNm: '표지', famYn: 'Y', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 0, title: '표지'},
        // {otmlFileNm: '[New] 3大 인생자산 분석 요약본', famYn: 'Y', myYn: 'Y', indivYn: 'Y', PrpsYn: '', title: '[New] 3大 인생자산 분석 요약본'},
        {otmlFileNm: '보장자산요약', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 1, title: '보장자산 요약'},
        {otmlFileNm: '보장자산요약(세대)', famYn: 'Y', myYn: 'Y', indivYn: 'N', PrpsYn: '', sortNo: 2, title: '[세대]보장자산 요약'},
        {otmlFileNm: '계약현황분석(개인)', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 3, title: '계약 현황 분석'},
        {otmlFileNm: '계약현황분석(세대)', famYn: 'Y', myYn: 'Y', indivYn: 'N', PrpsYn: '', sortNo: 4, title: '[세대]계약 현황 분석'},
        {otmlFileNm: '상품별 보장내역 분석', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 5, title: '상품별 보장내역 분석'},
        {otmlFileNm: '설계 전 기간별 보장내역 분석', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 6, title: '설계 전 기간별 보장내역 분석'},
        {otmlFileNm: '가족구성원 보장내역 분석', famYn: 'Y', myYn: 'Y', indivYn: 'N', PrpsYn: '', sortNo: 7, title: '[세대]가족구성원 보장내역 분석'},
        {otmlFileNm: '통합보장자산 전/후 비교', famYn: 'Y', myYn: 'Y', indivYn: 'Y', PrpsYn: 'Y', sortNo: 8, title: '[세대]설계 후 보장 전후 분석(상품제안)'},
        {otmlFileNm: '개인별 보장 전/후 비교', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: 'Y', sortNo: 9, title: '개인별 보장 전후 비교'},
        {otmlFileNm: '기간별 보장 전/후 비교', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: 'Y', sortNo: 10, title: '설계 후 기간별 보장내역 분석'},
        {otmlFileNm: '가족 통합 보장 전/후 비교', famYn: 'Y', myYn: 'Y', indivYn: 'N', PrpsYn: 'Y', sortNo: 11, title: '[세대]가족 통합 보장 전후 비교'},
        {otmlFileNm: '생손보 실손 가입현황', famYn: 'N', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 12, title: '실손정보 조회결과'},
        {otmlFileNm: '기타안내', famYn: 'Y', myYn: 'Y', indivYn: 'Y', PrpsYn: '', sortNo: 13, title: '기타안내'}
      ],
      // 시니어
      gtplSeniReportTile: [
        {otmlFileNm: '시니어 표지', sortNo: 0, title: '표지'},
        {otmlFileNm: '시니어 니즈환기', sortNo: 1, title: '니즈환기'},
        {otmlFileNm: '당사/타사 보장설계 요약', sortNo: 2, title: '계약별 현황'},
        {otmlFileNm: '생손보 실손 가입현황', sortNo: 3, title: '실손정보 조회결과'},
        {otmlFileNm: '시니어 계약별 보장 내역', sortNo: 4, title: '계약별 보장 내역'},
        {otmlFileNm: '보험가입한도상세인쇄형', sortNo: 5, title: '계약 상세분석'},
        {otmlFileNm: '시니어 안내장', sortNo: 6, title: '안내장'}
      ],
      // 요약형
      gtplSmryReportTile: [
        {otmlFileNm: 'MY기본형_표지', sortNo: 0, title: '표지'},
        {otmlFileNm: '당사/타사 보장설계 요약', sortNo: 1, title: '보험계약 현황'},
        {otmlFileNm: '생손보 실손 가입현황', sortNo: 2, title: '실손 보험 현황'},
        {otmlFileNm: '당사/타사 상품별 보장자산', sortNo: 3, title: '계약별 보장 내용'},
        {otmlFileNm: '보험가입한도상세인쇄형', sortNo: 4, title: '계약 상세분석'},
        {otmlFileNm: '안내사항 요약형', sortNo: 5, title: '안내사항'}
      ],
      // 상세형
      gtplUntyReportTile: [
        {otmlFileNm: 'MY기본형_표지', sortNo: 0, title: '표지'},
        {otmlFileNm: '회사소개', sortNo: 1, title: '회사소개'},
        {otmlFileNm: '당사/타사 보장설계 요약', sortNo: 2, title: '보험계약 현황'},
        {otmlFileNm: '생손보 실손 가입현황', sortNo: 3, title: '실손 보험 현황'},
        {otmlFileNm: '건강자산 인체해부도', sortNo: 4, title: '인체해부도'},
        {otmlFileNm: '당사/타사 상품별 보장자산', sortNo: 5, title: '계약별 보장 내용'},
        {otmlFileNm: '설계 전 기간별 보장내역 분석', sortNo: 6, title: '기간별 보장 내용'},
        {otmlFileNm: '니즈환기', sortNo: 7, title: '니즈환기'},
        {otmlFileNm: '통합보장자산 전/후 비교', PrpsYn: 'Y', sortNo: 8, title: '컨설팅 후 보장 요약'},
        {otmlFileNm: '개인별 보장 전/후 비교', PrpsYn: 'Y', sortNo: 9, title: '컨설팅 후 보장 내용'},
        {otmlFileNm: '기간별 보장 전/후 비교', PrpsYn: 'Y', sortNo: 10, title: '컨설팅 후 기간별 보장 내용'},
        {otmlFileNm: '보험가입한도상세인쇄형', sortNo: 11, title: '계약 상세분석'},
        {otmlFileNm: '타사상품담보상세형', sortNo: 12, title: '담보 상세분석'},
        {otmlFileNm: '기타안내', sortNo: 13, title: '안내사항'}
      ],

      pParams: {},
      pollingCount: 0,
      interval: '',
      insrLmtChkContDtlExtYn: true, // 신정원데이터 존재여부: 미존재시 '계약 상세분석' 항목 제외
      selListOtcomAcontInfoCgoYn: false, // 세대원 타사계약정보 조회 함수 호출여부        

      popupCT160P: {}, // 보장발송 popup
      popupCT161P: {}, // 보장발송 고객수신목록 popup
      isMSPSA063P: {}, // 비대면화상상담 고객수신목록 popup
      lv_HeaderviewScrollCompID: '',
      lv_CT161_showPopup: true,
      lv_CT161_pParams: {}, 
      lv_mailObj: {},
      // 이메일고객목록 Object
      lv_custListObj: {}, 
      lv_LmsKkot_dob: '',
      lv_dob: '',
      moListDisabled: false,   
      bottonScCd: 0, 
      lv_prtType: 'A',        
      };
  },
    
   /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
    ***********************************************************************************/
  computed: {
      mockCheckCount () {
        return this.selectItems.length
      }
  },
    
  watch: {
     // table내 record가 갖는 checkbox 선택 시 후처리
      selectItems (obj) {
        // "안내사항" 인쇄목록 default 체크
        if (this.radioValue1 !== '3' && this.radioValue1 !== '4') {
          let lv_defaultCheck = false
          for (let i in obj) {
            if (obj[i].fileNm[0] === 'sfcbjp013') {
              lv_defaultCheck = true
            }
          }
          if (!lv_defaultCheck) {
            // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC064'])
            let lv_vm = this
            this.tableData.slice(0).forEach(function (item, index, array) {
              if (item.fileNm[0] === 'sfcbjp013') {
                lv_vm.selectItems.push(item)
              }
            })
            // return
          }
        }
        // this.selectItems = this.tableData
        if (this.selectItems.length !== this.tableData.length) {
          this.isSelectAll = false
        } else {
          if (this.tableData.length !== 0) {
             this.isSelectAll = true
          }
      }

     },
     // empty view 확인 용도
     selectedValue2 (num) {
        if (this.showPopup) this.fn_changeTableDate(num)
     },
     showPopup (on) {
        if (on) {
          this.fn_getPrmsRgstdt()      // 고객정보 및 가입설계 정보보조회
          // this.fn_selGtplHoshInfo()
          this.fn_printList()          //  출력물목록조회 
          // this.selectedValue2 = { key: '00', label: '전체 세대원' }
        } else {
          this.tableData = []
          this.radioValue1 = '1'
          this.radioValue2 = '1'
          this.selectedValue2 = { key: '', label: '' }
          this.insrLmtChkContDtlExtYn = true
          this.selListOtcomAcontInfoCgoYn = false
        }
    },

     // [보장분석고도화PJT] 라디오 선택에 따른 선택 목록 저장기능 추가. 2020-11-27
     radioValue1 () {
      if (this.radioValue1 === '3' || this.radioValue1 === '4') {
        if (this.radioValue1Save !== '3' && this.radioValue1Save !== '4') {
          this.selectItemsSave = this.selectItems.slice(0)
        }
        this.selectItems.splice(0, this.selectItems.length)
      } else {
        if (this.radioValue1Save === '3' || this.radioValue1Save === '4') {
          if (this.radioValue2 === '0' || this.radioValue2 === '1' || this.radioValue2 === '2') {
            this.selectItems = this.selectItemsSave
          } else {
            // '기간별' 목록은 체크 선택되지 않은 상태 처리
            let arrayItem = []
            this.tableData.slice(0).forEach(function (item, index, array) {
             if (!(item.fileNm[0] === 'sfcbjp006' || item.fileNm[0] === 'sfcbjp010' || item.fileNm[0] === 'sfcbjp011')) {
                arrayItem.push(item)
             }
            })
            this.selectItems = arrayItem
          }
        }
      }
      this.radioValue1Save = this.radioValue1
      if (this.radioValue1 === '3' || this.radioValue1 === '4') {
          this.moListDisabled = true
      } else {
        this.moListDisabled = false
      } 
     },

     radioValue2 () {
      this.selectedValue2 = {
        key: this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId,
        label: this.getStore('ctStore').getters.getCustBaseInfo.data.custNm,
        mnCustRltnCd: this.getStore('ctStore').getters.getCustBaseInfo.data.mnCustRltnCd,
        PrpsYn: this.fn_getPrpsYn()
      }
     }
   },

  created () {
    // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)    
            // 상단 검색, 카테고리 버튼 생성
      this.actionSlot = {
        actionComponent: {
          template:'<span><mo-button shape="border" class="ns-btn-round btn-title-style-1">보장설계</mo-button> <mo-icon icon-size="30px" type="picto" >more</mo-icon></span>',
          methods: {

          }
        }
      }
   
      // this.fn_chkNofacCnslAuth() // 화상상담 권한 체크
      // [보장분석고도화PJT] 선택목록 저장 변수 추가. 2020-11-27
      this.radioValue1Save = '1'
      this.selectItemsSave = []
      this.showPopup = true

      // let today = moment(new Date().toISOString()).format('YYYYMMDD')
      // let dday = localStorage.getItem('TOOLTIPSHOWCT')
      // if (this.$bizUtil.isEmpty(dday) || dday !== today) {
      //   this.tooltipShow = true
      // } else {
      //   this.tooltipShow = false
      // }
  },

  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCT160P')

    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
  // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack) 
  },  
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_BackBtnClicked() {

      console.log('fn_BackBtnClicked  exec')
      this.showPopup = false
      // this.firstOpen = 1
      //this.$router.go(-1)
      let lv_Vm = this
      lv_Vm.$emit('onPopupClose')
    },

    fn_btnChk(str) {
      let data = this.selectedData1.filter((item) => {return item.key == str})
      console.log('data >> ', data)
      this.lv_printTypeNm = data[0].label
      this.lv_prtType = data[0].key
      this.btn_index = str
      console.log('data key >> ', this.lv_prtType)
      this.fn_printType_select_Close()
    },

 
    fn_printType_select_Open() {
       this.$refs.nsbottomsheet.open() 
    },
    fn_printType_select_Close() { 
      this.$refs.nsbottomsheet.close()
    },

    fn_print_Open() {
       this.$refs.print_nsbottomsheet.open(); 
    },
    fn_print_Close() { 
      this.$refs.print_nsbottomsheet.close();
    },

    fn_preview_Open() {
       this.$refs.previewDspchConfirm_nsbottomsheet.open(); 
    },
    fn_preview_Close() { 
      this.$refs.previewDspchConfirm_nsbottomsheet.close();
    },

    // table 전체 선택 처리
    selectAllItemsFunc (state, bottonScCd) {
      if (state) {
        // if (bottonScCd === 0) {
        //   this.firstOpen = 4
        // }
        // // checked
        // if (this.firstOpen <= 3) {
        //   let arrayItem = []
        //   this.tableData.slice(0).forEach(function (item, index, array) {
        //     if (!(item.fileNm[0] === 'sfcbjp006' || item.fileNm[0] === 'sfcbjp010' || item.fileNm[0] === 'sfcbjp011')) {
        //       arrayItem.push(item)
        //     }
        //   })
        //   this.selectItems = arrayItem
        //   this.firstOpen += 1
        // } else {
        // this.selectItems = this.tableData.slice(0)
        if (bottonScCd === 2) {
          // if (this.selectItemsSENIinit) this.selectItemsSENI = this.tableData // 시니어
          // if (this.selectItemsSMRYinit) this.selectItemsSMRY = this.tableData // 요약형
          // if (this.selectItemsUNTYinit) this.selectItemsUNTY = this.tableData // 상세형
          if (this.radioValue2Bef === '0' && !this.selectItemsSENIinit) this.selectItemsSENI = this.selectItems // 시니어
          else if (this.radioValue2Bef === '1' && !this.selectItemsSMRYinit) this.selectItemsSMRY = this.selectItems // 요약형
          else if (this.radioValue2Bef === '2' && !this.selectItemsUNTYinit) this.selectItemsUNTY = this.selectItems // 상세형
          this.radioValue2Bef = this.radioValue2
          let td = this.tableData
          let si = []
          if (this.radioValue2 === '0') { // 시니어 선택
            si = this.selectItemsSENI
          } else if (this.radioValue2 === '1') { // 요약형 선택
            si = this.selectItemsSMRY
          } else if (this.radioValue2 === '2') { // 상세형 선택
            si = this.selectItemsUNTY
          }
          let siSave = []
          for (let i = 0; i < td.length; i++) {
            for (let j = 0; j < si.length; j++) {
              if (td[i].fileNm[0] === si[j].fileNm[0]) {
                siSave.push(td[i])
              }
            }
          }
          this.selectItems = siSave
        } else {
          this.selectItems = this.tableData.slice(0)
        }
      } else {
        // unchecked
        this.selectItems.splice(0, this.selectItems.length)
      }

    },

     // bottom-bar 해제 처리
    cancelSeletItemsFunc () {
      this.selectItems.splice(0, this.selectItems.length)
      this.isSelectAll = false
    },

   /******************************************************************************
    * Function명  : fn_OzPrintEmailPrct
    * 설명        : 확인 버튼 (as-is : 1-인쇄 2-이메일 3-미리보기 4-LMS 5-알림톡 6-비대면화상상담)
    *                         (as-is : 1-인쇄 2-이메일 3-LMS 4-알림톡 5-비대면화상상담 6-미리보기)
    ******************************************************************************/
    fn_OzPrintEmailPrct (param, cust, bf, dspch) {
      console.log('>>>>>>', param, cust, bf, dspch)
      // 이메일, 전체세대원 선택 후 확인 시 이메일전송 불가 메시지
      if (dspch === '2' && cust.key === '00') {
        this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC054'])
        return
      }

      // 서류 정렬
      let lv_Vm = this
      param.sort(function (a, b) {
        if (a.sortNo < b.sortNo) {
          return -1
        } else if (a.sortNo > b.sortNo) {
          return 1
        } else {
          let custList = lv_Vm.customerCollectionVO
          let aKeySortNo = custList.findIndex(x => { return (a.chnlCustId === x.key) })
          let bKeySortNo = custList.findIndex(x => { return (b.chnlCustId === x.key) })

          if (aKeySortNo < bKeySortNo) {
            return -1
          } else if (aKeySortNo > bKeySortNo) {
            return 1
          } else {
            return 0
          }
        }
      })

      let voArray = []
      for (let i in param) {
        let gtplCllgOtmlVO = {
          'fileNm': param[i].fileNm[0],
          'chnlCustId': param[i].chnlCustId,
          'indvPrtYn': param[i].fileNm[1]
        }
        voArray.push(gtplCllgOtmlVO)
      }

      // [보장분석고도화PJT] 발행전송구분코드 세팅 추가
      let pblTrnsScCd = ''
      if (dspch === '1') {         //인쇄
        pblTrnsScCd = '36'
      } else if (dspch === '2') {  //이메일 
        pblTrnsScCd = '37'
      } else if (dspch === '6') {  // 미리보기
        pblTrnsScCd = '35'
      }

      let pParams = {
        'custNm': cust.key === '00' ? lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.custNm : cust.label,
        'cnstgCustNm': cust.key === '00' ? lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.custNm : cust.label,
        'chnlCustId': cust.key === '00' ? lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId : cust.key,
        'bfPrntYn':  lv_Vm.lv_prtType,    //bf.key,
        'pblTrnsScCd': pblTrnsScCd,
        'dspchDocuTypCd': this.radioValue2,
        gtplCllgOtmlVO: voArray,
        // 2023.09.05 반영 예정 추가 파라미터
        conctScCd: '3',
        infoOrgplCd: this.getStore('ctStore').getters.getTcompKcisSc // 당사,신정원 정보출처구분
      }
      this.pParams = pParams

      if (dspch === '1') {
         this.fn_printDspchConfirm()
      // } else if (dspch === '2') {
      //   this.emailOzDspchConfirm = true
      // [보장분석고도화PJT] 수신고객목록 팝업 연계. 2020-11-27
      } else if (dspch === '2' || dspch === '3' || dspch === '4') {          //2-이메일 3-lms 4-알림톡
        this.lv_CT161_showPopup = true
        this.lv_CT161_pParams = pParams
        this.fn_custReceiveList()   //고객수신목록조회 popup
      } else if (dspch === '5') {
        this.fn_nofacCnslConfirm()   // 비대면화상상담 자료 저장
      } else {
        this.fn_previewOzDspchConfirm()    // 미리보기 
      }
    },
    /******************************************************************************
    * Function명  : fn_printDspchConfirm
    * 설명        : 인쇄 실행
    ******************************************************************************/
    fn_printDspchConfirm() {
      console.log('pParam :', this.pParams)
      let pParams = this.pParams
      let lv_Vm = this
      const trnstId = 'txTSSCT06S1'
      const auth = 'I'

      window.vue.getStore('progress').dispatch('UPDATE', true)
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        let successYn = response.msgComm.msgCd
        if (successYn === 'EFWS003') {
          // lv_Vm.getStore('toast').dispatch('ADD', '인쇄되었습니다.')
          // lv_Vm.fn_print_Close()
          lv_Vm.$refs.actionSnackbar.show() // 토스트 팝업 호출
          setTimeout(() => {
            lv_Vm.fn_print_Close()
          }, 300)
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    snackbarAction(noti) {
      this.$refs.actionSnackbar.close(noti) // 토스트 팝업 닫기
    },
    /******************************************************************************
    * Function명  : fn_previewOzDspchConfirm
    * 설명        : OZ 미리보기
    * [보험거래문서디지털화PJT] OZ 미리보기 추가
    ******************************************************************************/
    fn_previewOzDspchConfirm() {
      this.fn_preview_Close()  // 미리보기 확인 버텀시트  담기 
      if (this.radioValue1 === '3' || this.radioValue1 === '4') {
          this.fn_preview_LmsKkot()
      } else {    
          this.fn_preview_PrtEmail()
      }
    },
    /******************************************************************************
    * Function명  : fn_previewOzDspchConfirm
    * 설명        : OZ 미리보기
    * [보험거래문서디지털화PJT] OZ 미리보기 추가
    ******************************************************************************/
    fn_preview_LmsKkot() {

      let lv_Vm = this

      let params = {}
      params.cnsltNo = lv_Vm.getStore('userInfo').getters.getUserInfo.userId // 컨설턴트번호
      params.chnlCustId = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId // 채널고객id


      
      const trnstId = 'txTSSCT76S2'
      const auth = 'S'
       console.log('@@ 160p_data result txTSSCT76S2  parm>>' , params )
      this.post(lv_Vm, params, trnstId, auth).then(function (response) {
        let successYn = response.msgComm.msgCd

        if (successYn === 'EFWS003') {
          let result = response.body
           console.log('@@ 160p_data result 1111>>' , result )
          lv_Vm.lv_LmsKkot_dob = result.dob.substring(2, 8)
        
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })      

         // LMS(txTSSCT99S2), 알림톡(txTSSCT99S3) - 통합영업자료기본정보번호 구분을 위한 분기
        const trnstId_1 = this.pRadioValue === '3' ? 'txTSSCT99S2' : 'txTSSCT99S3'
        const auth_1 = 'S'
        
        let voArray = []

        let gtplCllgOtmlVO = {
          'fileNm': 'sfcbjp020', // 보장분석LMS/KKOT출력물 - 모바일 간단보장분석
          'chnlCustId': lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId
        }
        voArray.push(gtplCllgOtmlVO)

        let pParams = {
          'custNm': lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.custNm,
          'cnstgCustNm': lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.custNm,
          'chnlCustId': lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId,
          gtplCllgOtmlVO: voArray,
          conctScCd: '3',
          infoOrgplCd: this.getStore('ctStore').getters.getTcompKcisSc // 당사,신정원 정보출처구분
        }
        console.log('@ 미리보기 --문자 / 알림톡 고객정보 pParams >>' , pParams)

        this.post(lv_Vm, pParams, trnstId_1, auth_1)
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              lv_Vm.lv_imgFileNm = response.body.tbtPblNo
              lv_Vm.lv_dob = lv_Vm.lv_LmsKkot_dob

              console.log('@ 미리보기 --문자 / 알림톡 문서번호>>' , response.body)

              let obj = response.body
              let lv_TransId = 'txTSSCT98S1'
              let auth = 'S'
              // moblBusnScCd 업무구분코드, eltrnDoctScCd ??, eltrnDoctId 태블릿발행번호(tbtPblNo)-이메일보내기output으로 나옴.
               pParams = {moblBusnScCd: 'CM', eltrnDoctScCd: '07', eltrnDoctId: obj.tbtPblNo}
               console.log('obj.tbtPblNo :::', obj.tbtPblNo)
               lv_Vm.fn_PreviewPollingCount(obj, pParams, lv_TransId, auth)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            lv_Vm.getStore('confirm').dispatch('ADD', 'PDF 생성에 실패하였습니다.\n\n' + error)
          })
    },
    /******************************************************************************
    * Function명  : fn_previewOzDspchConfirm
    * 설명        : OZ 미리보기
    * [보험거래문서디지털화PJT] OZ 미리보기 추가
    ******************************************************************************/
    fn_preview_PrtEmail() {

      let pParams = this.pParams
      let lv_Vm = this
      const trnstId = 'txTSSCT99S1'
      const auth = 'I'

      window.vue.getStore('progress').dispatch('ADD')
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        let successYn = response.msgComm.msgCd
        if (successYn === 'EFWS003') {
          console.log(response.body)
          let obj = response.body
          let lv_TransId = 'txTSSCT98S1'
          let auth = 'S'
          // moblBusnScCd 업무구분코드, eltrnDoctScCd ??, eltrnDoctId 태블릿발행번호(tbtPblNo)-이메일보내기output으로 나옴.
          pParams = {moblBusnScCd: 'CM', eltrnDoctScCd: '07', eltrnDoctId: obj.tbtPblNo}
          console.log('obj.tbtPblNo :::', obj.tbtPblNo)
          lv_Vm.fn_PreviewPollingCount(obj, pParams, lv_TransId, auth)
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
        window.vue.getStore('progress').dispatch('SUB')
      })
    },
    /******************************************************************************
      * Function명 : fn_chkNofacCnslAuth
      * 설명       : 비대면 상담을 위한 사용자 권한 체크
    ******************************************************************************/
    // fn_chkNofacCnslAuth () {
    //   let userRoleList = window.vue.getStore('userInfo').getters.getUserInfo.userRoleList
    //   if (!this.$bizUtil.isEmpty(userRoleList)) {
    //     for (let i = 0; i < userRoleList.length; i++) {
    //       if (userRoleList[i] === '154') {
    //         this.isNofacCnslAuth = true
    //         break
    //       }
    //     }
    //   }
    // },

    /******************************************************************************
    * Function명  : fn_nofacCnslConfirm
    * 설명        : [비대면화상상담시스템구축PJT]2021.06.22 화상상담자료(PDF) 저장
    ******************************************************************************/
    fn_nofacCnslConfirm () {
      let pParams = this.pParams
      let lv_Vm = this
      const trnstId = 'txTSSCT99S1'
      const auth = 'I'

      window.vue.getStore('progress').dispatch('ADD')
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        let successYn = response.msgComm.msgCd
        if (successYn === 'EFWS003') {
          console.log(response.body)
          let obj = response.body
          let lv_TransId = 'txTSSCT98S1'
          let auth = 'S'
          // moblBusnScCd 업무구분코드, eltrnDoctScCd ??, eltrnDoctId 태블릿발행번호(tbtPblNo)-이메일보내기output으로 나옴.
          pParams = {moblBusnScCd: 'CM', eltrnDoctScCd: '07', eltrnDoctId: obj.tbtPblNo}
          console.log('obj.tbtPblNo :::', obj.tbtPblNo)
          lv_Vm.fn_nofacCnslPollingCount(obj, pParams, lv_TransId, auth)
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
        window.vue.getStore('progress').dispatch('SUB')
      })
    },
    /******************************************************************************
    * Function명  : fn_nofacCnslPollingCount
    * 설명        : [비대면화상상담시스템구축PJT]2021.06.22 1.2초 동안 함수 호출 하고 pollingCount 값을 늘린다
    ******************************************************************************/
    fn_nofacCnslPollingCount (obj, pram, trnstId, auth) {
      let lv = this

      this.interval = setInterval(function () {
        lv.fn_nofacCnslPdfYNCheck(obj, pram, trnstId, auth)
        lv.pollingCount++
        console.log('count ::', lv.pollingCount)
      }, 1200)
    },
    /******************************************************************************
    * Function명  : fn_nofacCnslPdfYNCheck
    * 설명        : [비대면화상상담시스템구축PJT]2021.06.22 화상상담자료(PDF) 확인
    ******************************************************************************/
    fn_nofacCnslPdfYNCheck (obj, pram, trnstId, auth) {
      let lv_Vm = this
      lv_Vm.$bizUtil.ctUtils.fn_DoTrz2SvrLegacy(lv_Vm, pram, trnstId, auth).then(function (response) {
        let trfoCmpltYn = response.body.trfoCmpltYn
        let msg = response.msgComm.msgCd

        if (msg === 'EFWS003') {
          if (trfoCmpltYn === '' || trfoCmpltYn === 'N') {
            if (lv_Vm.pollingCount === 26) {
              clearInterval(lv_Vm.interval)
              lv_Vm.pollingCount = 0
              lv_Vm.getStore('confirm').dispatch('ADD', 'PDF 변환 중 에러가 발생했습니다.')
              window.vue.getStore('progress').dispatch('SUB')
            }
          } else if (trfoCmpltYn === 'Y') {
            clearInterval(lv_Vm.interval)
            lv_Vm.pollingCount = 0
            lv_Vm.fn_InsNofacCnslData(response.body, lv_Vm.selectItems)
            // lv_Vm.showPopup = false
            window.vue.getStore('progress').dispatch('SUB')
          }
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명 : fn_InsNofacCnslData
    * 설명       : [비대면화상상담시스템구축PJT]2021.06.22 자료 저장
    * Params     : 비대면채널고객ID, 비대면상담문서ID, 비대면업무구분코드,비대면컨설턴트ID,문서삭제여부,비대면상담문서명,비대면상담문서경로명
    * Return     : 결과 코드(1:성공 0:실패)
    ******************************************************************************/
    fn_InsNofacCnslData (pathInfo, docInfo) {
      let lv_Vm = this
      let pParams = {}
      let ctNofacCnslListVO = []
      let pParam = {}
      pParam.nofacChnlCustId = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId // 비대면채널고객ID
      pParam.nofacCnslDoctId = pathInfo.eltrnDoctFileNm // 비대면상담문서ID
      pParam.nofacCnslBusnScCd = 'CT' // 비대면업무구분코드(컨설팅:CT)
      pParam.nofacCnsltNo = lv_Vm.getStore('userInfo').getters.getUserInfo.userId // 비대면컨설턴트ID
      pParam.doctDeltYn = ' ' // 문서삭제여부
      pParam.nofacCnslDoctNm = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.custNm + '_보장설계'// 비대면상담문서명(고객명__보장설계)
      pParam.nofacCnslDoctPathNm = pathInfo.eltrnDoctFilePathNm // 비대면상담문서경로명
      ctNofacCnslListVO.push(pParam)
      pParams.ctNofacCnslListVO = ctNofacCnslListVO
      const trnstId = 'txTSSCT80S1'
      const auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_Vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              if (response.body.resultCd === 1) {
                // lv_Vm.getStore('confirm').dispatch('ADD', '비대면상담 자료 저장을 완료했습니다.')
                lv_Vm.lv_NofacCnslCust = {}
                lv_Vm.lv_NofacCnslCust.custNm = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.custNm // 검색고객명
                lv_Vm.lv_NofacCnslCust.chnlCustId = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId // 채널고객ID
                lv_Vm.lv_NofacCnslCust.age = lv_Vm.getStore('ctStore').getters.getCllgCustVO.data[0].insrAge + '세' // 고객 보험나이
                //lv_Vm.isTSSCT601P = true
                lv_Vm.fn_nofacCnslBtnClick()   // 비대면 화상상담 수신모록 popup 호출 
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명  : fn_PreviewPollingCount
    * 설명        : 1.2초 동안 함수 호출 하고 PreviewPollingCount 값을 늘린다
    ******************************************************************************/
    fn_PreviewPollingCount (obj, pram, trnstId, auth) {
      let lv = this

      this.interval = setInterval(function () {
        lv.fn_PreviewPdfYNCheck(obj, pram, trnstId, auth)
        lv.pollingCount++
        console.log('count ::', lv.pollingCount)
      }, 1200)
    },
    /******************************************************************************
    * Function명  : fn_PreviewPdfYNCheck
    * 설명        : PDF 생성 여부
    ******************************************************************************/
    fn_PreviewPdfYNCheck (obj, pram, trnstId, auth) {
      let lv_Vm = this
      lv_Vm.$bizUtil.ctUtils.fn_DoTrz2SvrLegacy(lv_Vm, pram, trnstId, auth).then(function (response) {
        let trfoCmpltYn = response.body.trfoCmpltYn
        let msg = response.msgComm.msgCd
        let email = ''

        if (msg === 'EFWS003') {
          if (trfoCmpltYn === '' || trfoCmpltYn === 'N') {
            if (lv_Vm.pollingCount === 26) {
              clearInterval(lv_Vm.interval)
              lv_Vm.pollingCount = 0
              lv_Vm.getStore('confirm').dispatch('ADD', 'PDF 변환 중 에러가 발생했습니다.')
              window.vue.getStore('progress').dispatch('SUB')
            }
          } else if (trfoCmpltYn === 'Y') {
            clearInterval(lv_Vm.interval)
            lv_Vm.pollingCount = 0
            for (let i in lv_Vm.customerCollectionVO) {
              if (lv_Vm.customerCollectionVO[i].key === obj.chnlCustId) {
                email = lv_Vm.customerCollectionVO[i].email
              }
            }
            let mailObj = {
              emailAddr: email,
              emailDspchVO: obj,
              PSExtInfoAddrSVO: response.body
            }
            lv_Vm.fn_SaveFOMFile(mailObj)
            // lv_Vm.$parent.$refs.TSSCT002P.mailObj = mailObj
            // lv_Vm.$parent.$refs.TSSCT002P.showPopup = true
            // lv_Vm.showPopup = false
            window.vue.getStore('progress').dispatch('SUB')
          }
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    }, 
    /******************************************************************************
    * Function명  : fn_printList
    * 설명        : 보장플랜출력물목록조회
    ******************************************************************************/
    fn_printList () {
      let lv_Vm = this
      let pParams = {}
      const trnstId = 'txTSSCT05S1'
      const auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        let gtplCllgOtmlVO = response.body.gtplCllgOtmlVO
        let getObj = {}
        let getArray = []
        for (let i in gtplCllgOtmlVO) {
          getObj = {}
          getObj = gtplCllgOtmlVO[i]
          getArray.push(getObj)
        }
        lv_Vm.gtplCllgOtmlVO = getArray
        console.log('txTSSCT05S1  ::', lv_Vm.gtplCllgOtmlVO)

      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_selGtplHoshInfo@
    * 설명        : 세대원정보 조회
    ******************************************************************************/
    fn_selGtplHoshInfo () {

      let lv_Vm = this
      let pParams = {
        mnCustId: this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId
      }
      console.log('fn_selGtplHoshInfo proc -세대원정보 조회::' , pParams)

      let covanCustInfo = this.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.gtplCllgCustVO // 보장플랜종합고객VO
      let entplVO = this.getStore('ctStore').getters.getCustcovrAnly.data.entplVO // 가입설계리스트

      console.log('fn_selGtplHoshInfo proc -보장플랜종합고객VO 조회::' , covanCustInfo)
      console.log('fn_selGtplHoshInfo proc -가입설계리스트 조회::' , entplVO)

      // 전체 세대원이 마지막에 위치 [보장분석고도화PJT]
      // let custArry = [{key: '00', label: '전체 세대원'}]
      let custArry = []

      this.$bizUtil.ctUtils.fn_SelHems(lv_Vm, pParams).then(function (response) {
        let rtnArray = response
        let getObj = {}
        console.log('fn_SelHems proc -가입설계리스트 세대원 설계 조회::' , rtnArray)

        for (let i in rtnArray) {
          if (rtnArray[i].gtplCllgCustInfoPrcusCnsntVO.gtplInfoPrcusCnsntYmd !== ' ') {
            getObj = {}
            getObj.key = rtnArray[i].chnlCustId
            getObj.label = rtnArray[i].custNm
            getObj.email = rtnArray[i].emailAddr
            getObj.mnCustRltnCdNm = rtnArray[i].mnCustRltnCdNm
            getObj.mnCustRltnCd = rtnArray[i].mnCustRltnCd
            getObj.PrpsYn = 'N'

            // 성능이슈에 따른 가입설계 제안 조회 수정
            for (let j in entplVO) {
              if (rtnArray[i].chnlCustId === entplVO[j].mnisdChnlCustId) {
                if (entplVO[j].prpslStatCd === '01') {
                  getObj.PrpsYn = 'Y' // 상품제안 없음
                  break
                } else {
                  getObj.PrpsYn = 'N' // 상품제안 없음
                }
              } else {
                getObj.PrpsYn = 'N' // 상품제안 없음
              }
            }

            for (let idx in covanCustInfo) {
              if (rtnArray[i].chnlCustId === covanCustInfo[idx].chnlCustId) {
                // 성능이슈에 따른 가입설계 제안 조회 수정
                // if (lv_Vm.$bizUtil.isEmpty(covanCustInfo[idx].entplPrpslPrdtVO)) {
                //   getObj.PrpsYn = 'N' // 상품제안 없음
                // } else {
                //   if (covanCustInfo[idx].entplPrpslPrdtVO.length > 0) {
                //     getObj.PrpsYn = 'Y' // 상품제안 있음
                //   } else {
                //     getObj.PrpsYn = 'N' // 상품제안 없음
                //   }
                // }
                // 가입설계 제안이 없는 경우에는 기계약 제안도 체크함
                if (getObj.PrpsYn !== 'Y') {
                  for (let j in covanCustInfo[idx].tcompAcontVO01) {
                    if (covanCustInfo[idx].tcompAcontVO01[j].prpslStatCd === '02') {
                      getObj.PrpsYn = 'Y' // 당사 기계약 제외가 있음
                    }
                  }
                  for (let j in covanCustInfo[idx].otcomAcontVO01) {
                    if (covanCustInfo[idx].otcomAcontVO01[j].prpslStatCd === '02') {
                      getObj.PrpsYn = 'Y' // 타사 기계약 제외가 있음
                    }
                  }
                }
                break
              }
            }
            custArry.push(getObj)
          }
        }
        custArry.push({key: '00', label: '전체 세대원'})
        lv_Vm.customerCollectionVO = custArry

        // 보기방식 통합형인 경우 디폴트 대상고객 정보 조회 [보장분석고도화PJT]
        // if (lv_Vm.customerCollectionVO.length > 2) {
        //   lv_Vm.selectedValue2 = { key: '00', label: '전체 세대원' }
        // } else {
        //   lv_Vm.selectedValue2 = {
        //     key: lv_Vm.customerCollectionVO[1].key,
        //     label: lv_Vm.customerCollectionVO[1].label,
        //     mnCustRltnCd: lv_Vm.customerCollectionVO[1].mnCustRltnCd,
        //     PrpsYn: lv_Vm.customerCollectionVO[1].PrpsYn
        //   }
        // }

        lv_Vm.selectedValue2 = {
          key: lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId,
          label: lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.custNm,
          mnCustRltnCd: lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.mnCustRltnCd,
          PrpsYn: lv_Vm.fn_getPrpsYn()
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    
    fn_getPrpsYn () {
      let prpsYn = ''
      this.customerCollectionVO.slice(0).forEach(vo => {
        if (vo.key === this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId) {
          prpsYn = vo.PrpsYn
        }
      })
      return prpsYn
    },
    /******************************************************************************
    * Function명  : fn_getGtplCllgOtmlVO
    * 설명        : 첨부파일명을 가져오기 위한 리턴 함수
    ******************************************************************************/
   fn_getGtplCllgOtmlVO (otmlFileNm) {
      let lv_Vm = this
      let retVal = []
      for (let i in lv_Vm.gtplCllgOtmlVO) {
        if (otmlFileNm === lv_Vm.gtplCllgOtmlVO[i].otmlFileNm) {
          retVal.push(lv_Vm.gtplCllgOtmlVO[i].fileNm)
          retVal.push(lv_Vm.gtplCllgOtmlVO[i].indvPrtYn)
          return retVal
        }
      }
    },
    /******************************************************************************
    * Function명  : fn_close
    * 설명        : 팝업 닫기
    ******************************************************************************/
    fn_close () {
      // this.tableData = []
      // this.gtplCllgOtmlVO = []
      // this.gtplReportTile = []
      // this.selectedValue2 = { key: '', label: '' }
      this.showPopup = false
      // this.firstOpen = 1
    },
    /******************************************************************************
    * Function명  : fn_getPrmsRgstdt@
    * 설명        : 가입설계 불러오기
    ******************************************************************************/
    fn_getPrmsRgstdt () {
      let lv_TargetSelf = this
      Promise.all([this.$bizUtil.ctUtils.fn_PrmsRgstdt(lv_TargetSelf, true), lv_TargetSelf.$bizUtil.ctUtils.fn_PrpslCovrAnly(lv_TargetSelf)])
        .then(function (response) {
          lv_TargetSelf.$bizUtil.ctUtils.fn_DrwRgstdt(lv_TargetSelf)
          lv_TargetSelf.fn_selGtplHoshInfo()
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_SaveFOMFile
    * 설명        : Native로 다운로드할 PDF 파일 목록 구성
    * Params     : N/A
    * Return     : N/A
    ******************************************************************************/
    fn_SaveFOMFile (mailObj) {
      // 로컬 테스트인 경우
      // if (process.env.NODE_ENV === 'local') {
      //   // PDF Viewer를 통해 실행할 파일 목록과 파일 제목 목록을 저장하는 변수 초기화
      //   // 파일명tssB파일제복tssA파일명2tssB파일제목2tssA와 같은 형식으로 목록 구성
      //   let viewerTargetFileList = ''
      //   // PDF Viewer 실행 전 Native 저장소로 다운로드할 PDF 파일 정보를 저장하는 Array
      //   // FOM 파일의 경우는 문서변환에 의해 변환된 정보를 담고 있는 VO를 이용
      //   let savePdfTargetItemList = []
      //   // let selectedUntySlmtItem = this.lv_SelectedSingleItem
      //   let itemObj = {}
      //   itemObj.eltrnDoctFilePathNm = ''
      //   itemObj.eltrnDoctFileNm = 'test.pdf'
      //   itemObj.localFileNm = 'test.pdf'
      //   itemObj.fileTitle = '건강자산 보장설계'

      //   savePdfTargetItemList.push(itemObj)

      //   // Native 저장소로 다운로드가 완료된 후 PDF Viewer로 노출할 PDF 파일과 해당 PDF 파일의 제목을 구성
      //   savePdfTargetItemList.forEach(item => {
      //     try {
      //       viewerTargetFileList += item.eltrnDoctFileNm + 'tssB' + item.fileTitle + 'tssA'
      //     } catch (error) {
      //       if (CtConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
      //     }
      //   })

      //   // window.alert('viewerTargetFileList=' + viewerTargetFileList)
      //   // Native PDF Viewer 호출
      //   this.fn_CallPdfViewer(viewerTargetFileList)
      //   if (CtConstants.DEBUG_MODE_CON) window.console.log('■■■■■ TSSSA001M ■■■■■ fn_ChkDocsTrfo viewerTargetFileList=' + viewerTargetFileList)
      // } else {
      // NAS에 저장된 PDF 파일 다운로드 시도 후 성공횟수
      // let successCount = 0
      // NAS에 저장된 PDF 파일 다운로드 시도 횟수
      // let tryCount = 0
      // NAS에 저장된 PDF 파일을 다운받기 위한 정보를 저장하기 위한 컬렉션
      // PDF Viewer 실행 전 Native 저장소로 다운로드할 PDF 파일 정보를 저장하는 Array
      // FOM 파일의 경우는 문서변환에 의해 변환된 정보를 담고 있는 VO를 이용
      let savePdfTargetItemList = []
      // PDF Viewer Native에 전달한 파일 목록을 저장하는 변수
      // test.pdf|[테스트],test2.pdf|[테스트2] 와 같은 문자열로 전달
      // test.pdf|[테스트] 와 같이 단일 파일을 보여줄 경우에도 뒤에 ,를 붙여서 파라미터로 전달
      // PDF Viewer를 통해 실행할 파일 목록과 파일 제목 목록을 저장하는 변수 초기화
      // 파일명tssB파일제복tssA파일명2tssB파일제목2tssA와 같은 형식으로 목록 구성
      let viewerTargetFileList = ''
      // PDF 파일인지 그렇지 않은지 여부에 따라 다운로들할 파일명 뒤에 확장자를 붙일지 말지 결정
      // FOM 파일일 경우
      // pdfApndFilePathNm에 저장된 'file.path.fmlmt' 와
      // pdfApndFileStoreNm에 저장된 '30002012030102301203213'와 같은 파일명에 '.pdf' 조합
      // lstTrtDtm에 저장된 날짜를 8자리 문자열로 조합하여 파일을 다운로드
      // -> 최종경로 : /filedown.do?path=file.path.fmlmt&filename=20190220/000300336246415221102209102.pdf
      // let lv_Vm = this
      let itemObj = {}
      let tempDateDir = mailObj.PSExtInfoAddrSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
      itemObj.fileTitle = '삼성생명 보장설계'
      itemObj.eltrnDoctFilePathNm = mailObj.PSExtInfoAddrSVO.eltrnDoctFilePathNm
      if (mailObj.PSExtInfoAddrSVO.eltrnDoctId.indexOf('.pdf') < 0) {
        itemObj.localFileNm = mailObj.PSExtInfoAddrSVO.eltrnDoctId + '.pdf'
        itemObj.eltrnDoctFileNm = tempDateDir + '/' + mailObj.PSExtInfoAddrSVO.eltrnDoctFileNm + '.pdf'
      } else {
        itemObj.localFileNm = mailObj.PSExtInfoAddrSVO.eltrnDoctId + '.pdf'
        itemObj.eltrnDoctFileNm = tempDateDir + '/' + mailObj.PSExtInfoAddrSVO.eltrnDoctFileNm + '.pdf'
      }

      savePdfTargetItemList.push(itemObj)

      // Native PDF Viewer 호출 전 PDF 파일을 Native 저장공간(/sli/tss/comm/fmlmt)에 다운로드
      savePdfTargetItemList.forEach(item => {
        // if (CtConstants.DEBUG_MODE_CON) window.console.log('■■■■■ TSSSA001M ■■■■■ fn_ChkDocsTrfo savePdfTargetItemList"s item=' + item.eltrnDoctFileNm)
        if (process.env.NODE_ENV === 'development') {
          item.fileTitle = '삼성생명 보장설계'
          item.localFileNm = 'test.pdf'
          item.eltrnDoctFileNm = 'test.pdf'
          item.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
        }
        try {
          this.fn_FdpSaveFile(item)
            .then(response => {
              // if (CtConstants.DEBUG_MODE_CON) window.alert('■■■■■ TSSSA001M ■■■■■ savedFileName : ' + response)
              // 파일명과 PDF Viewer에 노출될 제목을 '|'로 결합하여 한 파일에 대한 정보를 구성
              // viewerTargetFileList += response + '|' + item.fileTitle + ','
              viewerTargetFileList += response + 'tssB' + item.fileTitle + 'tssA'
              // viewerTargetFileList += response
              // 단일 선택일 경우는 바로 PDF Viewer 호출
              this.fn_CallPdfViewer(viewerTargetFileList)
            }).catch(error => {
              // window.alert('파일 다운로드 실패 콜백 : ' + new Error(error))
              // 단일 선택에 오류 발생 시 시도 횟수는 비교하지 않음.
              // 화면 깜박거림을 방지하기 위한 로딩바를 해제
              this.getStore('progress').getters.getState.isShow = false
              // 오류 팝업 메시지 출력
              this.getStore('confirm').dispatch('ADD', '총 1건 중 1건 저장이 실패하였습니다')
              if (CtConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
              window.vue.getStore('progress').dispatch('SUB')
            })
        } catch (error) {
          if (CtConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          window.vue.getStore('progress').dispatch('SUB')
        }
      })
      // }
    },
    /******************************************************************************
    * Function명 : fn_FdpSaveFile
    * 설명        : Native 저장 장소로 실제 PDF 파일을 다운로드
    *              실제 파일 존재 유무는 다음과 같은 URL을 이용 확인 가능
    *              https://vtss.samsunglife.com:8410/filedown.do?path=file.path.fmlmt&filename=/20190122/000300336200252107122109102.pdf&type=pdf
    * Params     : 다운로드할 PDF 정보를 담고있는 개체
    * Return     : N/A
    ******************************************************************************/
    fn_FdpSaveFile (obj) {
      return new Promise((resolve, reject) => {
        // let tempFileName = obj.eltrnDoctFileNm
        // .pdf 확장자가 없을 경우 확장자 첨부
        if (obj.localFileNm.indexOf('.pdf') < 0) {
          obj.localFileNm = obj.localFileNm + '.pdf'
        }
        window.fdpbridge.exec('downloadPlugin', {
          path: obj.eltrnDoctFilePathNm,
          filename: obj.eltrnDoctFileNm, // src Directory + fileName
          type: 'pdf',
          localpath: '/sli/tss/comm/fmlmt', // local Directory(안내 자료는 fmlmt 폴더 사용)
          localfilename: obj.localFileNm, // local fileName
          rename: 'N' // 파일명 중복에 관계없이 신규 파일 생성 여부
        }, (result) => {
          resolve(result.data)
        }, (result) => {
          reject(result.data)
        })
      })
    },
    /******************************************************************************
    * Function명 : fn_CallPdfViewer
    * 설명        : PDF Viewer 호출
    * Params     : PDF Viewer를 통해 보여줄 PDF 파일 및 제목 목록
    * Return     : N/A
    ******************************************************************************/
    fn_CallPdfViewer (viewerTargetFileList) {
      // PDF Viewer를 통해 PDF 확인은 가능하나 툴팁 메뉴 사용은 불가
      let lv_Vm = this
      // PDF 뷰어 호출
      window.fdpbridge.exec('callPdfViewerPlugin', {
        path: '/sli/tss/comm/fmlmt',
        file: viewerTargetFileList,
        menu: '0000'
      }, function (result) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
        lv_Vm.getStore('progress').getters.getState.isShow = false
        // 성공콜백
        // // PDF Viewer에서 툴팁 메뉴를 눌렀을 경우

        // lv_Vm.fn_CallPdfViewerNextStep(lv_Vm, result.data)
      }, function (result) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
        lv_Vm.getStore('progress').getters.getState.isShow = false
        // 실패콜백
        // alert('실패콜백=' + result.data)
      })
    },

    /******************************************************************************
    * Function명  : fn_changeTableDate
    * 설명        : 세부인쇄방식에 따라 선택된 고객에 해당하는 Table 그리드 데이터 변경 [보장분석고도화PJT]
    ******************************************************************************/
    fn_changeTableDate (num) {
      console.log(num)
      let selectedPrpsYn = num.PrpsYn
      if (this.radioValue2 === '0') {
        let gtplSeniReportTile = this.gtplSeniReportTile
        // let custList = this.customerCollectionVO
        let obj = {}
        this.tableData = []

        for (let i in gtplSeniReportTile) {
          if (!this.insrLmtChkContDtlExtYn && gtplSeniReportTile[i].title === '계약 상세분석') continue
          if (!this.insrLmtChkContDtlExtYn && gtplSeniReportTile[i].title === '담보 상세분석') continue
          if (!this.$bizUtil.isEmpty(gtplSeniReportTile[i].PrpsYn) && gtplSeniReportTile[i].PrpsYn === 'Y') {
            if (selectedPrpsYn === 'N') continue
          }
          obj = {}
          obj.custNm = this.getStore('ctStore').getters.getCustBaseInfo.data.custNm
          obj.chnlCustId = this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId
          obj.otmlFileNm = gtplSeniReportTile[i].title
          obj.fileNm = this.fn_getGtplCllgOtmlVO(gtplSeniReportTile[i].otmlFileNm)
          obj.sortNo = gtplSeniReportTile[i].sortNo
          this.tableData.push(obj)
        }

        if (this.selectItemsSENIinit) {
          this.selectItemsSENI = this.tableData // 시니어
          // this.selectItems = this.tableData
          this.selectItemsSENIinit = false
        }
        this.selectAllItemsFunc(true, 2)

        if (this.tableData.length < 0) {
          this.cancelSeletItemsFunc()
        } else {
          if (!this.selListOtcomAcontInfoCgoYn) this.fn_SelListOtcomAcontInfo()
        }
      } else if (this.radioValue2 === '1') {
        let gtplSmryReportTile = this.gtplSmryReportTile
        // let custList = this.customerCollectionVO
        let obj = {}
        this.tableData = []

        for (let i in gtplSmryReportTile) {
          if (!this.insrLmtChkContDtlExtYn && gtplSmryReportTile[i].title === '계약 상세분석') continue
          if (!this.insrLmtChkContDtlExtYn && gtplSmryReportTile[i].title === '담보 상세분석') continue
          if (!this.$bizUtil.isEmpty(gtplSmryReportTile[i].PrpsYn) && gtplSmryReportTile[i].PrpsYn === 'Y') {
            if (selectedPrpsYn === 'N') continue
          }
          obj = {}
          obj.custNm = this.getStore('ctStore').getters.getCustBaseInfo.data.custNm
          obj.chnlCustId = this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId
          obj.otmlFileNm = gtplSmryReportTile[i].title
          obj.fileNm = this.fn_getGtplCllgOtmlVO(gtplSmryReportTile[i].otmlFileNm)
          obj.sortNo = gtplSmryReportTile[i].sortNo
          this.tableData.push(obj)
        }

        if (this.selectItemsSMRYinit) {
          this.selectItemsSMRY = this.tableData // 요약형
          // this.selectItems = this.tableData
          this.selectItemsSMRYinit = false
        }
        this.selectAllItemsFunc(true, 2)

        if (this.tableData.length < 0) {
          this.cancelSeletItemsFunc()
        } else {
          if (!this.selListOtcomAcontInfoCgoYn) this.fn_SelListOtcomAcontInfo()
        }
      } else if (this.radioValue2 === '2') {
        let gtplUntyReportTile = this.gtplUntyReportTile
        // let custList = this.customerCollectionVO
        let obj = {}
        this.tableData = []

        for (let i in gtplUntyReportTile) {
          if (!this.insrLmtChkContDtlExtYn && gtplUntyReportTile[i].title === '계약 상세분석') continue
          if (!this.insrLmtChkContDtlExtYn && gtplUntyReportTile[i].title === '담보 상세분석') continue
          if (!this.$bizUtil.isEmpty(gtplUntyReportTile[i].PrpsYn) && gtplUntyReportTile[i].PrpsYn === 'Y') {
            if (selectedPrpsYn === 'N') continue
          }
          obj = {}
          obj.custNm = this.getStore('ctStore').getters.getCustBaseInfo.data.custNm
          obj.chnlCustId = this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId
          obj.otmlFileNm = gtplUntyReportTile[i].title
          obj.fileNm = this.fn_getGtplCllgOtmlVO(gtplUntyReportTile[i].otmlFileNm)
          obj.sortNo = gtplUntyReportTile[i].sortNo
          this.tableData.push(obj)
        }

        if (this.selectItemsUNTYinit) {
          this.selectItemsUNTY = this.tableData // 상세형
          // this.selectItems = this.tableData
          this.selectItemsUNTYinit = false
        }
        this.selectAllItemsFunc(true, 2)

        if (this.tableData.length < 0) {
          this.cancelSeletItemsFunc()
        } else {
          if (!this.selListOtcomAcontInfoCgoYn) this.fn_SelListOtcomAcontInfo()
        }
      } else {
        let selectedKey = this.selectedValue2.key
        // let selectedCustRltnCd = num.mnCustRltnCd
        let gtplReportTile = this.gtplReportTile
        let custList = this.customerCollectionVO
        let obj = {}

        if (selectedKey !== '00') {
          this.tableData = []
          for (let i in gtplReportTile) {
            if (gtplReportTile[i].indivYn === 'Y') {
            // if (selectedCustRltnCd > '03' && gtplReportTile[i].myYn === 'N') continue
              if (!this.$bizUtil.isEmpty(gtplReportTile[i].PrpsYn) && gtplReportTile[i].PrpsYn === 'Y') {
                if (selectedPrpsYn === 'N') continue
              }
              obj = {}
              obj.custNm = num.label
              obj.chnlCustId = num.key
              obj.otmlFileNm = gtplReportTile[i].title
              obj.fileNm = this.fn_getGtplCllgOtmlVO(gtplReportTile[i].otmlFileNm)
              obj.sortNo = gtplReportTile[i].sortNo
              this.tableData.push(obj)
            }
          }
        } else {
          let prnitArray = []
          let prpsYn = 'N'
          for (let k in custList) {
            if (custList[k].PrpsYn === 'Y') {
              prpsYn = 'Y'
              break
            }
          }
          for (let i in gtplReportTile) {
            if (gtplReportTile[i].famYn === 'N') {
              for (let j in custList) {
                if (j === (custList.length - 1).toString()) continue
                // if (custList[j].mnCustRltnCd > '03' && gtplReportTile[i].myYn === 'N') continue
                if (custList.length > 1 && gtplReportTile[i].otmlFileNm === '계약현황분석(개인)') continue
                if (!this.$bizUtil.isEmpty(gtplReportTile[i].PrpsYn) && gtplReportTile[i].PrpsYn === 'Y') {
                  if (custList[j].PrpsYn === 'N') continue
                }
                obj = {}
                obj.custNm = custList[j].label
                obj.chnlCustId = custList[j].key
                obj.otmlFileNm = gtplReportTile[i].title
                obj.fileNm = this.fn_getGtplCllgOtmlVO(gtplReportTile[i].otmlFileNm)
                obj.sortNo = gtplReportTile[i].sortNo
                prnitArray.push(obj)
              }
            } else {
              if (!this.$bizUtil.isEmpty(gtplReportTile[i].PrpsYn) && gtplReportTile[i].PrpsYn === 'Y' && prpsYn === 'N') continue
              obj = {}
              obj.custNm = this.getStore('ctStore').getters.getCustBaseInfo.data.custNm
              obj.chnlCustId = this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId
              obj.otmlFileNm = gtplReportTile[i].title
              obj.fileNm = this.fn_getGtplCllgOtmlVO(gtplReportTile[i].otmlFileNm)
              obj.sortNo = gtplReportTile[i].sortNo
              prnitArray.push(obj)
            }
          }
          this.tableData = prnitArray
        }
        // [보장분석고도화 PJT] '기간별' 목록은 체크 선택되지 않은 상태 처리
        let arrayItem = []
        this.tableData.forEach(function (item, index, array) {
          if (!(item.fileNm[0] === 'sfcbjp006' || item.fileNm[0] === 'sfcbjp010' || item.fileNm[0] === 'sfcbjp011')) {
            arrayItem.push(item)
          }
        })
        this.selectItems = arrayItem
        if (this.tableData.length < 0) {
          this.cancelSeletItemsFunc()
        }
      }
    },
    /******************************************************************************
    * Function명  : fn_SelListOtcomAcontInfo
    * 설명        : 세대원 타사계약정보 조회
    * Params      : 계약 상세분석 데이터가 없을 경우 목록에서 제외
    ******************************************************************************/
    fn_SelListOtcomAcontInfo () {
      let lv_Vm = this
      let trnstId = 'txTSSCT44S1'
      let auth = 'S'
      let pParams = {
        'mnCustId': lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId,
        'custNm': lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.custNm,
        conctScCd: '3',
        infoOrgplCd: this.getStore('ctStore').getters.getTcompKcisSc // 당사,신정원 정보출처구분

      }

      // 서비스 호출
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        // 성공여부 확인
        if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
          // 조회결과 확인
          if ( !response || !response.body || !response.body.gtplCllgCustVO || response.body.gtplCllgCustVO.length <= 0 ) {
            let lv_Msg = lv_Vm.$t('ct')['ECTC048'].replace('{0}', '세대원정보') // {0}이(가) 존재하지 않습니다.
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Msg)
            return
          }

          let gtplCllgCustSVO = response.body.gtplCllgCustVO
          let chkYn = false
          let chkAplcYn = false
          for ( let i = 0; i < gtplCllgCustSVO.length; i++ ) {
            if (gtplCllgCustSVO[i].chnlCustId === pParams.mnCustId) {
              // 신정원 데이터 미존재할 경우
              if ( gtplCllgCustSVO[i].pICustInsrLmtChkContDtlListVO) {
                for (let j = 0; j < gtplCllgCustSVO[i].pICustInsrLmtChkContDtlListVO.length; j++) {
                  if (gtplCllgCustSVO[i].pICustInsrLmtChkContDtlListVO[j].aplcYn === 'Y') {
                    chkAplcYn = true
                  }
                }
              }

              if (!chkAplcYn) {
                for (let t = 0; t < lv_Vm.tableData.length; t++) {
                  if (lv_Vm.tableData[t].otmlFileNm === '계약 상세분석' ) {
                    lv_Vm.tableData.splice(t, 1)
                    lv_Vm.selectAllItemsFunc(true, 1)
                    lv_Vm.insrLmtChkContDtlExtYn = false
                  }
                }
              }

              if (!chkAplcYn) {
                for (let t = 0; t < lv_Vm.tableData.length; t++) {
                  if (lv_Vm.tableData[t].otmlFileNm === '담보 상세분석' ) {
                    lv_Vm.tableData.splice(t, 1)
                    lv_Vm.selectAllItemsFunc(true, 1)
                    lv_Vm.insrLmtChkContDtlExtYn = false
                  }
                }
              }

              chkYn = true
            }
            if (chkYn) break
          }
          lv_Vm.selListOtcomAcontInfoCgoYn = true
        } else {
          // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          window.vue.error(response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        lv_Vm.getStore('confirm').dispatch('ADD', error)
        window.vue.error(error)
      })
    },    
    /******************************************************************************
    * Function명 : fn_custReceiveList
    * 설명       : 수신고객목록(MSPCT161P) 팝업 
    ******************************************************************************/
     fn_custReceiveList() {
      // 이메일 ,lms, 알림톡 관련  고객수신목록 팜업 호출
        this.popupCT161P = this.$bottomModal.open(MSPCT161P, {
              properties: {
                pPage : 'MSPCT160P',
                pRadioValue: this.radioValue1 ,   
                pShowPopup: this.lv_CT161_showPopup,
                pParams: this.lv_CT161_pParams
              },
              listeners: {
                onPopupConfirm: () => {
                  this.$bottomModal.close(this.popupCT161P);
                },
                onPopupClose: () => {
                  this.$bottomModal.close(this.popupCT161P);
                },
                onPopupCancel: () => {      // 이메일 발송후 emit시 수행
                }
              }
            },
            {
              properties: {
                noHeader: true
              }
            })
     },
    /******************************************************************************
     * Function명 : fn_nofacCnslBtnClick
     * 설명       : 화상상담자료조회popup호출 - MSPSA063P
     ******************************************************************************/
    fn_nofacCnslBtnClick() {
  
      // 화상상담자료조회popup호출
      let lv_Vm = this
      this.isMSPSA063P = this.$bottomModal.open(MSPSA063P, {
            properties: {
              pNofacCnslCust: lv_Vm.lv_NofacCnslCust        
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(this.isMSPSA063P);
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.isMSPSA063P);
              }
            }
          },
          {
            properties: {
              noHeader: true
            }
          })
    },
     fn_HistoryBack () {
        // 체크박스가 있는 경우
        if (this.selectItems.length > 0 ) {
                let alertMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'

                let alertObj = this.$bottomModal.open(MSPBottomAlert, {
                  properties: {
                      type: 'B',
                      content: alertMsg,
                      title_pos_btn: "예",
                      title_neg_btn: "아니오"
                  },
                  listeners: {
                    onPopupConfirm: () => {
                     this.$bottomModal.close(alertObj)                
                      // 특정화면 이동 혹은 이전 화면 이동
                      //this.$router.go(-1)
                      console.log('back_1')
                      this.fn_BackBtnClicked()
                   },
                    onPopupClose: () => {
                     this.$bottomModal.close(alertObj)
                    }
                 }
               })
        } else {
            //this.$router.go(-1) // 이전화면으로 이동
            console.log('back_2')
            this.fn_BackBtnClicked()
         }
    },
    /******************************************************************************
    * Function명 : fn_closeTooltip
    * 설명       : 툴팁 닫기 및 로컬스토리지에 저장(다시 열리지 않음)
    ******************************************************************************/
    // fn_closeTooltip() {
    //   let today = moment(new Date().toISOString()).format('YYYYMMDD')
    //   localStorage.setItem('TOOLTIPSHOWCT', today)
    //   this.tooltipShow = false
    // }       
   }
  }
</script>
<style scoped>
  #listTooltip1:after {
    width: 0; height: 0;
    border-bottom: #3190FF 10px solid  ;
    border-left: transparent 10px solid;
    border-right: transparent 10px solid;
    left: -13px; top: 16px;
    vertical-align: center;
    transform:rotate(-90deg);
  }
  #listTooltip2:after {
    width: 0; height: 0;
    border-bottom: #3190FF 10px solid  ;
    border-left: transparent 10px solid;
    border-right: transparent 10px solid;
    left: -13px; top: 16px;
    vertical-align: center;
    transform:rotate(-90deg);
  }
</style>